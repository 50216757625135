import React, { useState } from "react";
import InstacartLogo from "../../assets/InstacartLogo.js";
import { useDispatch, useSelector } from "react-redux";
import { generateInstacartRecipePage } from "../../redux/recipe/recipesThunk.js";
import toast from "react-hot-toast";
import Spinner from "../DataTable/components/Spinner.js";
import InstacartCarrot from "../../assets/Instacart_Carrot.svg";

const styles = {
  dark: `
    flex items-center justify-center
    h-[46px]
    px-[18px] py-[16px]
    bg-[#003D29] 
    text-[#FAF1E5] 
    font-medium text-sm
    rounded-full
    space-x-[10px]
    hover:shadow-md
    transition-all
  `,
  light: `
    flex items-center justify-center
    h-[46px]
    px-[18px] py-[16px]
    bg-[#FAF1E5]
    text-[#0A610A] 
    font-medium text-sm
    rounded-full
    border-[0.5px] border-[#EFE9E1]
    space-x-[10px]
    hover:shadow-md
    transition-all
  `,
  white: `
    flex items-center justify-center
    h-[46px]
    px-[18px] py-[16px]
    bg-[#FFFFFF] 
    text-[#000000] 
    font-medium text-sm
    rounded-full
    border-[0.5px] border-[#E8E9EB]
    space-x-[10px]
    hover:shadow-md
    transition-all
  `,
};

export default function InstacartBtn({ style = "white" }) {
  const dispatch = useDispatch();
  const currentRecipe = useSelector((state) => state.recipe.currentRecipe);
  const currentUser = useSelector((state) => state.user.currentUser);
  const [loading, setLoading] = useState(false); // State for loading spinner

  const handleClick = async (e) => {
    e.preventDefault();
    console.log("Generating Instacart Link...");

    const userDiet = "omnivore"; // Temporarily hardcoded for testing
    const ingredients = currentRecipe[`default_ingredients`] || [];
    const instructionsHTML = currentRecipe[`default_instructions`] || "";

    const splitInstructions = instructionsHTML.split("</p>");
    const cleanedInstructions = splitInstructions
      .map((instruction) => instruction.replace("<p>", "").trim())
      .filter((instruction) => instruction.length > 0);

    if (cleanedInstructions.length === 0) {
      console.error("No valid instructions found.");
      toast.error("No valid instructions found.");
      return;
    }

    const cleanedIngredients = ingredients
      .map((ingredient) => {
        console.log(ingredient);
        const name =
          ingredient.title || ingredient.name || "Unnamed Ingredient";
        const display_text = name;
        const quantity = parseFloat(ingredient.qty) || 0;
        const unit = ingredient.measurement || "";

        const measurements = quantity > 0 && unit ? [{ quantity, unit }] : [];

        return {
          name,
          display_text,
          measurements,
        };
      })
      .filter(
        (ingredient) =>
          ingredient.name &&
          ingredient.display_text &&
          ingredient.measurements.length > 0
      );

    if (cleanedIngredients.length === 0) {
      console.error("No valid ingredients found.");
      toast.error("No valid ingredients found.");
      return;
    }

    if (!currentRecipe.title || !currentRecipe.primary_image) {
      console.error("Recipe title or image URL is missing.");
      toast.error("Recipe title or image URL is missing.");
      return;
    }

    const recipe = {
      title: currentRecipe.title,
      image_url: currentRecipe.primary_image,
      link_type: "recipe",
      instructions: cleanedInstructions,
      ingredients: cleanedIngredients,
      landing_page_configuration: {
        partner_linkback_url: "https://www.wwlprogram.com/",
        enable_pantry_items: true,
      },
    };

    console.log("Sending:");
    console.log(JSON.stringify(recipe, null, 2));

    try {
      setLoading(true); // Show spinner
      const result = await dispatch(
        generateInstacartRecipePage({ recipe })
      ).unwrap();
      window.open(result.url, "_blank");
    } catch (error) {
      console.error("Failed to generate Instacart recipe page:", error);
      toast.error("Failed to generate Instacart Link");
    } finally {
      setLoading(false); // Hide spinner
    }
  };

  return (
    <button
      onClick={handleClick}
      className={styles[style]}
      disabled={loading} // Disable button while loading
    >
      {loading ? (
        <>
          <Spinner size="5" />
          <div className="">Get Recipe Ingredients</div>
        </>
      ) : (
        <>
          <img
            className="h-[22px]"
            src={InstacartCarrot}
            alt="Instacart Carrot"
          />
          <div className="">Get Recipe Ingredients</div>
        </>
      )}
    </button>
  );
}
