import React, { useState } from "react";
import { SearchIcon } from "@heroicons/react/solid";
import { FilterIcon } from "@heroicons/react/outline";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearRecipes,
  updateSearchSettings,
} from "../../../redux/atlas/atlasSlice";
import { atlasSearchRecipes } from "../../../redux/atlas/atlasThunk";

const MobileSearchBar = ({ showFilters }) => {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initSearchTerm = useSelector((state) => state.atlas.searchTerm);
  const initIncludeIngredients = useSelector(
    (state) => state.atlas.includeIngredients
  );
  const initDontIncludeIngredients = useSelector(
    (state) => state.atlas.dontIncludeIngredients
  );
  const dietType = useSelector((state) => state.user.currentUser.primary_diet);

  const [includeIngredients, setIncludeIngredients] = useState(
    initIncludeIngredients
  );
  const [dontIncludeIngredients, setDontIncludeIngredients] = useState(
    initDontIncludeIngredients
  );

  const handleSearch = async () => {
    // Update global state just before searching
    await dispatch(
      updateSearchSettings({
        dietType: dietType.toLowerCase(),
        searchTerm: initSearchTerm,
        includeIngredients: includeIngredients,
        dontIncludeIngredients: dontIncludeIngredients,
      })
    );

    dispatch(clearRecipes());

    // Then, dispatch the action to perform the search
    dispatch(
      atlasSearchRecipes({
        restart: true,
      })
    );
  };

  const filterCount = includeIngredients.length + dontIncludeIngredients.length;

  return (
    <div className="bg-white flex items-center rounded-full overflow-hidden shadow-md font-chivo text-wwlBlue py-2 px-4 ">
      <div className="flex items-center flex-1">
        <div className="">
          <SearchIcon className="h-6 w-6 " aria-hidden="true" />
        </div>
        <input
          type="text"
          value={initSearchTerm}
          onChange={(e) => {
            dispatch(updateSearchSettings({ searchTerm: e.target.value }));
          }}
          placeholder="Search"
          className="flex-1 p-2 outline-none focus:outline-none focus:ring-0 border-0 "
          onKeyDown={(e) => {
            if (e.key === "Enter") handleSearch();
          }}
          onPaste={handlePaste}
        />
      </div>
      <div
        onClick={showFilters}
        className="relative bg-gray-200 rounded-full p-3 cursor-pointer hover:bg-gray-300"
      >
        <FilterIcon className="h-6 w-6" />
        {/* Badge implementation */}
        {filterCount > 0 && (
          <span className="absolute bottom-1 -right-1 flex h-5 w-5 flex items-center justify-center rounded-full bg-red-500 text-white text-xs">
            <span>{filterCount}</span>
          </span>
        )}
      </div>
    </div>
  );
};

function handlePaste(e) {
  e.preventDefault();
  const pastedText = (e.clipboardData || window.clipboardData).getData(
    "text/plain"
  );
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = pastedText;
  tempDiv.style.backgroundColor = "transparent";
  document.execCommand(
    "insertHTML",
    false,
    tempDiv.textContent || tempDiv.innerText
  );
}

export default MobileSearchBar;
