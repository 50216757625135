import React, { useState } from "react";
import InstacartLogo from "../../assets/InstacartLogo.js";
import { useDispatch, useSelector } from "react-redux";
import { generateInstacartShoppingListPage } from "../../redux/shoppingList/shoppingListThunk.js"; // Ensure this thunk exists
import toast from "react-hot-toast";
import Spinner from "../DataTable/components/Spinner.js";
import InstacartCarrot from "../../assets/Instacart_Carrot.svg";

const styles = {
  dark: `
      flex items-center justify-center
      h-[46px]
      px-[18px] py-[16px]
      bg-[#003D29] 
      text-[#FAF1E5] 
      font-medium text-sm
      rounded-full
      space-x-[10px]
      hover:shadow-md
      transition-all
    `,
  light: `
      flex items-center justify-center
      h-[46px]
      px-[18px] py-[16px]
      bg-[#FAF1E5]
      text-[#0A610A] 
      font-medium text-sm
      rounded-full
      border-[0.5px] border-[#EFE9E1]
      space-x-[10px]
      hover:shadow-md
      transition-all
    `,
  white: `
      flex items-center justify-center
      h-[46px]
      px-[18px] py-[16px]
      bg-[#FFFFFF] 
      text-[#000000] 
      font-medium text-sm
      rounded-full
      border-[0.5px] border-[#E8E9EB]
      space-x-[10px]
      hover:shadow-md
      transition-all
    `,
};

export default function InstacartShoppingListBtn({ style = "white" }) {
  const dispatch = useDispatch();
  const shoppingListData = useSelector(
    (state) => state.shoppingList.shoppingListData
  );
  const currentUser = useSelector((state) => state.user.currentUser);
  const [loading, setLoading] = useState(false); // State for loading spinner

  const handleClick = async (e) => {
    e.preventDefault();
    console.log("Generating Instacart Shopping List Link...");

    if (!shoppingListData || !shoppingListData.data) {
      console.error("Shopping list data is missing.");
      toast.error("Shopping list data is missing.");
      return;
    }

    const instructions = [
      "Select your preferred store.",
      "Review the items and adjust quantities as needed.",
      "Proceed to checkout when ready.",
    ];

    // Extract and clean ingredients from all categories
    const allIngredients = shoppingListData.data.flatMap(
      (category) => category.ingredients || []
    );

    const cleanedIngredients = allIngredients
      .map((ingredient) => {
        const name = ingredient.title || "Unnamed Ingredient";
        const display_text = ingredient.desiredTitle || name;
        const quantity = parseFloat(ingredient.qty) || 0;
        const unit = ingredient.measurement || "";

        const line_item_measurements =
          quantity > 0 && unit ? [{ quantity, unit }] : [];

        const filters = {
          brand_filters: [],
          health_filters: [],
        };

        return {
          name: name.toLowerCase(),
          quantity,
          unit,
          display_text,
          line_item_measurements,
          filters,
        };
      })
      .filter(
        (ingredient) =>
          ingredient.name &&
          ingredient.display_text &&
          ingredient.line_item_measurements.length > 0
      );

    if (cleanedIngredients.length === 0) {
      console.error("No valid ingredients found in the shopping list.");
      toast.error("No valid ingredients found in the shopping list.");
      return;
    }

    const shoppingList = {
      title: shoppingListData.title || "My Shopping List",
      image_url:
        "https://workweeklunch.com/wp-content/uploads/2022/05/bento-box-submark.svg",
      link_type: "shopping_list",
      expires_in: 30,
      country_code: "US",
      instructions: instructions,
      line_items: cleanedIngredients,
      landing_page_configuration: {
        partner_linkback_url: "https://www.wwlprogram.com/",
        enable_pantry_items: true,
      },
    };

    try {
      setLoading(true); // Show spinner
      const result = await dispatch(
        generateInstacartShoppingListPage({ shoppingList })
      ).unwrap();
      window.open(result.url, "_blank");
    } catch (error) {
      console.error("Failed to generate Instacart shopping list page:", error);
      toast.error("Failed to generate Instacart Link");
    } finally {
      setLoading(false); // Hide spinner
    }
  };

  return (
    <button
      onClick={handleClick}
      className={styles[style]}
      disabled={loading} // Disable button while loading
    >
      {loading ? (
        <>
          <Spinner size="5" />
          <div className="">Get Recipe Ingredients</div>
        </>
      ) : (
        <>
          <img
            className="h-[22px]"
            src={InstacartCarrot}
            alt="Instacart Carrot"
          />
          <div className="">Get Recipe Ingredients</div>
        </>
      )}
    </button>
  );
}
